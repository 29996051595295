import React, { useEffect, useState } from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

import { Card } from "@/components/Card";
import { useTheme } from "@/hooks/useTheme";
import { FileDetails } from "@/containers/FileDetails";

import { PreviewImage } from "@/containers/Converter/ConvertAndView/components/Preview/PreviewImage";
import { IConversionType } from "@/containers/Converter/types";
import { IFilesDetails } from "@/containers/Converter/common/types";
import { getConversionDetails } from "@/containers/Converter/common/helpers";
import { landingPagesMap } from "@/containers/Converter/maps";
import { DownloadHelpArticleModal } from "@/components/DownloadHelpArticleModal";
import { LOCAL_STORAGE_KEYS } from "@/constants/localStorage";
import { IFile } from "@/services/fileDetailsService";
import { ConvertAnother } from "./ConvertAnother";

interface IPreview {
  uploadedFileDetails: IFilesDetails;
  conversionType: IConversionType;
  onConvertAllArtboardsBtn: () => void;
  onConvertAnotherBtn: () => void;
}

export const Preview: React.FC<IPreview> = props => {
  const { t } = useTranslation("converter");
  const { css } = useFela();
  const { bp } = useTheme();
  const router = useRouter();

  const [
    showDownloadButtonModal,
    setShowDownloadButtonModal,
  ] = useState<boolean>(false);
  const [disableShowModal, setDisableShowModal] = useState<boolean>(false);

  const { uploadedFileDetails, conversionType } = props;

  const { conversionSource, conversionTarget } = getConversionDetails(
    conversionType,
  );

  const convertedFile = uploadedFileDetails[conversionTarget].full;
  const sourceFile = uploadedFileDetails[conversionSource].full;

  if (!convertedFile) {
    throw Error("Invalid state: convertedFile is null");
  }

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.uploadedFileName);
  }, []);

  const getFullFileName = (file: IFile, type: "source" | "target") => {
    const fileType = type === "source" ? conversionSource : conversionTarget;
    if (!file) return "";

    // Figma and FigJam files don't have extensions by default so we need to add them manually.
    if (fileType === "figma") return `${file.name}.fig`;
    if (fileType === "figjam") return `${file.name}.jam`;

    return file.name;
  };

  const handleDownloadButton = () => {
    if (disableShowModal) return;
    setShowDownloadButtonModal(true);
  };

  const handleCloseDownloadModal = () => {
    setDisableShowModal(true);
    setShowDownloadButtonModal(!showDownloadButtonModal);
  };

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      })}
      data-testid="converter-step--preview"
    >
      <PreviewImage previewUrl={uploadedFileDetails.previewUrl} />

      {sourceFile && (
        <FileDetails
          name={getFullFileName(sourceFile, "source")}
          size={sourceFile.size}
          modifiedDate={sourceFile.lastModified}
          customStyle={{
            marginLeft: 15,
            alignSelf: "flex-start",
            marginTop: 28,
          }}
        />
      )}

      <Card
        customStyle={{
          marginTop: 25,
          marginBottom: -6,
          boxShadow: "0 2px 22px 0 rgba(0, 0, 0, 0.08)",
          [bp.FROM_TABLET]: {
            padding: "28px 30px 28px 30px",
          },
          nested: {
            "> div:not(:first-of-type)": {
              marginTop: 20,
            },
          },
        }}
      >
        <FileDetails
          name={getFullFileName(convertedFile, "target")}
          size={convertedFile.size}
          modifiedDate={convertedFile.lastModified}
          downloadUrl={convertedFile.url}
          handleDownloadButton={handleDownloadButton}
        />
        <ConvertAnother
          title={t(`converter:preview.convertAnother`)}
          onClick={() => {
            router.push(landingPagesMap[conversionType] || "/");
          }}
        />
      </Card>

      {showDownloadButtonModal && (
        <DownloadHelpArticleModal
          onClose={handleCloseDownloadModal}
          fileName={convertedFile.name}
          conversionType={conversionType}
        />
      )}
    </div>
  );
};
